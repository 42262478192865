import events from 'events.js';
import {setupMenu} from 'menu.js';
import {HF_COOKIE} from 'core/constants.js';
import {localStorageRemoveItem} from 'core/utils.js';

function clearCookies() {
    localStorageRemoveItem(HF_COOKIE);
}

(async function () {
    await events.ready();
    setupMenu();
    clearCookies();
})();
